/**
 * Copyright 2023-2024 Highway9 Networks Inc.
 */
import { Route, Switch, useLocation } from "react-router-dom";
import {
  AUDITLOGS,
  DASHBOARD,
  DEVICES,
  EDGES,
  NETWORKS,
  RADIOS,
  SETTINGS,
  SIMULATORS,
  VMZ,
  HARDWARE_INVENTORY,
  PROFILES,
  IT_DASHBOARD,
} from "../constants/routes";
import { Suspense, lazy, useEffect, useState } from "react";
import { CircularProgress } from "@mui/material";
import onAppStart from "./onAppStart";
import Layout from "./Layout";
import LoginExpiryAlert from "~/components/shared/LoginExpiryAlert";
import { useAppSelector } from "~/store/hooks";
import { selectUserPermssions, selectUserPermssionsLoading } from "~/store/context/authSlice";

const Dashboard = lazy(() => import("../views/dashboard-new/Dashboard"));
const RadiosPanelNew = lazy(() => import("../views/radios/RadiosPanelNew"));
const EdgesPanel = lazy(() => import("../views/edges/EdgesPanel"));
const SubscribersPanel = lazy(() => import("../views/subscribers/SubscribersPanel"));
const DnnPanel = lazy(() => import("../views/dnn/DnnPanel"));
const VMZone = lazy(() => import("../views/vmz"));
const Diagnostics = lazy(() => import("../views/diagnostics/SimulatorPanel"));
const AuditLog = lazy(() => import("../views/audit/AuditLog"));
const AdminPanel = lazy(() => import("../views/adminstration/AdminPanel"));
const NotImplemented = lazy(() => import("../views/NotImplemented/NotImplemented"));
const HardwareInventoryPanel = lazy(() => import("../views/hardwareInventory/HarwareInventoryPanel"));
const Profiles = lazy(() => import("../views/profiles/ProfilePanel"));
const ITDashnoard = lazy(() => import("../views/ITDashboard"));

type LState = { 
  fromChat?: boolean;
} | undefined;

type Props = {};
const AppRoutes = (_props: Props) => {
  /**
   * Runs the onAppStart function when the component mounts.
   * This effect is responsible for performing any necessary setup or initialization tasks when the app starts.
   */
  useEffect(() => {
    onAppStart();
  }, []);

  const permissionsMap = useAppSelector(selectUserPermssions);
  const permissionsLoading = useAppSelector(selectUserPermssionsLoading);

  const history = useLocation<LState>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (history.state?.fromChat) {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);    
    }
  }, [history]);


  return (
    <Layout>
        <Suspense fallback={<Loading />}>
        { loading && <Loading overlay /> }
        <Switch>
          <Route exact path={DASHBOARD} component={Dashboard} />
          { permissionsMap["radio"]?.VIEW && <Route exact path={RADIOS} component={RadiosPanelNew} />}
          { permissionsMap["edge"]?.VIEW && <Route exact path={EDGES} component={EdgesPanel} />}
          { permissionsMap["device"]?.VIEW && <Route exact path={DEVICES} component={SubscribersPanel} />}
          { permissionsMap["dnn"]?.VIEW && <Route exact path={NETWORKS} component={DnnPanel} />}
          { permissionsMap["zone"]?.VIEW && <Route exact path={VMZ} component={VMZone} />}
          { permissionsMap["simulator"]?.VIEW && <Route exact path={SIMULATORS} component={Diagnostics} />}
          { permissionsMap["network"]?.VIEW && <Route exact path={SETTINGS} component={AdminPanel} />}
          { permissionsMap["mobilityProfile"]?.VIEW && <Route exact path={PROFILES} component={Profiles} />}
          { permissionsMap["log"]?.VIEW && <Route exact path={AUDITLOGS} component={AuditLog} />}
          { permissionsMap["vmcHardware"]?.VIEW && <Route exact path={HARDWARE_INVENTORY} component={HardwareInventoryPanel} />}
          { permissionsMap["itAdminDashboard"]?.VIEW && <Route exact path={IT_DASHBOARD} component={ITDashnoard} />}
          { permissionsLoading && <Route component={Loading} />}
          <Route component={NotImplemented} />
        </Switch>
      </Suspense>
      <LoginExpiryAlert />
    </Layout>
  );
};

export default AppRoutes;

type LoadingProps = {
  overlay?: boolean;
};

function Loading({ overlay }: LoadingProps) {
  return (
    <div
      style={{
        position: overlay ? "absolute" : undefined,
        top: 0,
        left: 0,
        backdropFilter: "blur(3px)",
        zIndex: 1000,
        
        display: "flex",
        width: overlay ? "80vw" : "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress color="primary" />
    </div>
  );
}
