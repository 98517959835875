import { jsx as r, jsxs as e, Fragment as h } from "react/jsx-runtime";
import { CircularProgress as u, Menu as v, MenuItem as f } from "@mui/material";
import { useChatbotContext as M } from "./hooks/index.js";
import "../../Chatbot.style-fdd416e7.js";
import z from "react";
import "../../map-85279df3.js";
import "../../server.browser-ec1ee1ca.js";
import "../atoms/Grid/Grid.js";
import "../atoms/Typography/Typography.js";
import "../../style.module-3bbdaa05.js";
import "../../DateTimeSelectorMenu-84657d02.js";
import "moment-timezone";
import "../../runtime-6393d96e.js";
import "../../TableContext-c60e49ca.js";
import "../ReactTable/SearchFilter.js";
import "../ReactTable/TablePaginationActions.js";
import "../../styles/theme.js";
import "highcharts-react-official";
import "highcharts";
import "../molecules/Widgets/WidgetTimelineGraph/WidgetTimelineGraph.styles.js";
import "../../transform-17824b7f.js";
import "../molecules/NetworkMap/NetworkMapUtils.js";
import N from "../../assets/icons/AgentIcon.js";
import "../../ChatbotContext-74017a6b.js";
import { c as o } from "../../createSvgIcon-0ab806e0.js";
const L = o(/* @__PURE__ */ r("path", {
  d: "M22 3.41 16.71 8.7 20 12h-8V4l3.29 3.29L20.59 2 22 3.41zM3.41 22l5.29-5.29L12 20v-8H4l3.29 3.29L2 20.59 3.41 22z"
}), "CloseFullscreen"), O = o(/* @__PURE__ */ r("path", {
  d: "M12 8c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
}), "MoreVert"), V = o(/* @__PURE__ */ r("path", {
  d: "M21 11V3h-8l3.29 3.29-10 10L3 13v8h8l-3.29-3.29 10-10z"
}), "OpenInFull"), k = o([/* @__PURE__ */ r("circle", {
  cx: "9",
  cy: "9",
  r: "4"
}, "0"), /* @__PURE__ */ r("path", {
  d: "M9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm7.76-9.64-1.68 1.69c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z"
}, "1")], "RecordVoiceOver"), b = o(/* @__PURE__ */ r("path", {
  d: "M12.99 9.18c0-.06.01-.12.01-.18 0-2.21-1.79-4-4-4-.06 0-.12.01-.18.01l4.17 4.17zm-6.1-3.56L4.27 3 3 4.27l2.62 2.62C5.23 7.5 5 8.22 5 9c0 2.21 1.79 4 4 4 .78 0 1.5-.23 2.11-.62L19.73 21 21 19.73l-8.62-8.62-5.49-5.49zM9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm7.76-9.64-1.68 1.69c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z"
}), "VoiceOverOff"), Z = ({ subtitle: l, title: n, component: t, maximize: c, onSizeChange: i, onClose: s, actionMenu: m }) => {
  const { isProcessing: p, transcription: a } = M();
  function d() {
    a.toggleTranscription(), a.enabled && a.stopSpeaking();
  }
  return t || /* @__PURE__ */ e("div", { className: "chatbot-header", children: [
    /* @__PURE__ */ e("div", { className: "title", children: [
      /* @__PURE__ */ e("span", { className: "chatbot-header-title", children: [
        /* @__PURE__ */ r(N, { size: 28 }),
        " ",
        n
      ] }),
      l && /* @__PURE__ */ r("span", { className: "subtitle", children: l })
    ] }),
    /* @__PURE__ */ e("div", { className: "chatbot-actions", children: [
      p && /* @__PURE__ */ r(u, { size: 23 }),
      m && /* @__PURE__ */ r(C, { menu: m }),
      /* @__PURE__ */ r("div", { className: "action", onClick: d, children: a.enabled ? /* @__PURE__ */ r(k, {}) : /* @__PURE__ */ r(b, {}) }),
      /* @__PURE__ */ r("div", { className: "action", onClick: () => i == null ? void 0 : i(!c), children: c ? /* @__PURE__ */ r(V, {}) : /* @__PURE__ */ r(L, {}) }),
      /* @__PURE__ */ r("div", { className: "action", onClick: () => s == null ? void 0 : s(), children: "✖" })
    ] })
  ] });
};
function C({ menu: l }) {
  const [n, t] = z.useState(null);
  return /* @__PURE__ */ e(h, { children: [
    /* @__PURE__ */ r("div", { className: "action", onClick: (c) => t(c.currentTarget), children: /* @__PURE__ */ r(O, {}) }),
    /* @__PURE__ */ r(
      v,
      {
        anchorEl: n,
        open: !!n,
        onClose: () => t(null),
        children: l.map(
          (c, i) => c.hide ? null : /* @__PURE__ */ e(f, { onClick: () => {
            c.onClick(), t(null);
          }, children: [
            c.icon,
            "  ",
            c.title
          ] }, i)
        )
      }
    )
  ] });
}
export {
  Z as default
};
