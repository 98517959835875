var g = Object.defineProperty;
var y = (c, e, t) => e in c ? g(c, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : c[e] = t;
var l = (c, e, t) => (y(c, typeof e != "symbol" ? e + "" : e, t), t);
let s = 5;
class f {
  constructor(e) {
    l(this, "socket");
    l(this, "events");
    l(this, "type");
    this.socket = null, this.type = "text", this.events = {
      systemConecting: () => {
        console.log("Connecting to socket");
      },
      systemError: (t) => {
        console.error(t);
      },
      systemMessage: (t) => {
        console.log(t);
      },
      ...e
    };
  }
  connect(e, { token: t, type: o, tzOffset: h }) {
    var i, k;
    try {
      this.socket || (this.socket = new WebSocket(e), o && (this.type = o), (k = (i = this.events).systemConecting) == null || k.call(i), this.socket.onopen = () => {
        console.log("Socket connection established"), t && this.send("<<create_session>> " + t + " " + h), s = 5;
      }, this.socket.onerror = (n) => {
        var r, a;
        (a = (r = this.events).systemError) == null || a.call(r, JSON.stringify(n));
      }, this.socket.onclose = () => {
        var n, r;
        (r = (n = this.events).systemMessage) == null || r.call(n, `Socket closed. Reconnecting in ${s} seconds`), this.socket = null, setTimeout(() => {
          this.connect(e, { token: t, type: o }), s = Math.min(s * 2, 60);
        }, s * 1e3);
      });
    } catch (n) {
      throw console.log(n), console.log(`'Error connecting to socket. Reconnecting in ${s} seconds`), this.socket = null, setTimeout(() => {
        this.connect(e, { token: t, type: o }), s = Math.min(s * 2, 60);
      }, s * 1e3), n;
    }
  }
  disconnect() {
    this.socket && (this.socket.close(), this.socket = null);
  }
  send(e) {
    var o, h;
    let t;
    switch (this.type) {
      case "text":
        t = e;
        break;
      case "json":
        t = JSON.stringify(e);
        break;
      case "binary":
        t = new Blob([JSON.stringify(e)], { type: "application/json" });
        break;
      default:
        t = e;
        break;
    }
    try {
      this.socket && this.socket.send(t);
    } catch (i) {
      throw console.log(i), (h = (o = this.events).systemError) == null || h.call(o, JSON.stringify(i)), "Error sending message to socket";
    }
  }
  on(e, t) {
    this.socket && this.socket.addEventListener(e, t);
  }
}
export {
  f as default
};
